/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import Script from "next/script";
import Head from "next/head";
import AOS from "aos";
import { UserProvider } from "@auth0/nextjs-auth0";
import * as fbq from "../helpers/fbpixel";
import * as gtag from "../helpers/gtag";

import "../styles/globals.scss";

import "aos/dist/aos.css";

function MyApp({ Component, pageProps }) {
  // const router = useRouter();

  // useEffect(() => {
  //   // This pageview only triggers the first time (it's important for Pixel to have real information)
  //   fbq.pageview();

  //   const handleRouteChange = () => {
  //     fbq.pageview();
  //     gtag.pageview(url);
  //   };

  //   router.events.on("routeChangeComplete", handleRouteChange);
  //   return () => {
  //     router.events.off("routeChangeComplete", handleRouteChange);
  //   };
  // }, [router.events]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Script
        src="https://kit.fontawesome.com/9eab383de3.js"
        strategy="afterInteractive"
        crossorigin="anonymous"
      />
      <Script
        id="fbpixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Head>
        <title>My Digital Moment</title>
        <meta name="robots" content="all" />
        <meta name="author" content="My Digital Moment" />
        <meta name="copyright" content="My Digital Moment" />

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <link sizes="57x57" href="/images/icons/apple-touch-icon-57x57.png" />
        <link
          sizes="114x114"
          href="/images/icons/apple-touch-icon-114x114.png"
        />
        <link sizes="72x72" href="/images/icons/apple-touch-icon-72x72.png" />
        <link
          sizes="144x144"
          href="/images/icons/apple-touch-icon-144x144.png"
        />
        <link sizes="60x60" href="/images/icons/apple-touch-icon-60x60.png" />
        <link
          sizes="120x120"
          href="/images/icons/apple-touch-icon-120x120.png"
        />
        <link sizes="76x76" href="/images/icons/apple-touch-icon-76x76.png" />
        <link
          sizes="152x152"
          href="/images/icons/apple-touch-icon-152x152.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/icons/favicon-196x196.png"
          sizes="196x196"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/icons/favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/icons/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/icons/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/icons/favicon-128.png"
          sizes="128x128"
        />
        <meta name="application-name" content="My Digital Moment" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta
          name="msapplication-TileImage"
          content="/images/icons/mstile-144x144.png"
        />
        <meta
          name="msapplication-square70x70logo"
          content="/images/icons/mstile-70x70.png"
        />
        <meta
          name="msapplication-square150x150logo"
          content="/images/icons/mstile-150x150.png"
        />
        <meta
          name="msapplication-wide310x150logo"
          content="/images/icons/mstile-310x150.png"
        />
        <meta
          name="msapplication-square310x310logo"
          content="/images/icons/mstile-310x310.png"
        />
        <link
          rel="shortcut icon"
          href="https://mydigitalmoment.com/favicon.ico"
        />
        <meta name="theme-color" content="#000000" />
      </Head>
      <UserProvider>
        <Component {...pageProps} />
      </UserProvider>
    </>
  );
}

export default MyApp;

Date.prototype.yyyymmddhhmm = function yyyymmddhhmm() {
  const yyyy = this.getFullYear();
  const mm = this.getMonth() + 1;
  const dd = this.getDate();
  const hh = this.getHours();
  const min = this.getMinutes();

  if (Number.isNaN(yyyy) || Number.isNaN(mm) || Number.isNaN(dd)) {
    return undefined;
  }

  return `${yyyy}-${(mm > 9 ? "" : "0") + mm}-${(dd > 9 ? "" : "0") + dd}T${
    (hh > 9 ? "" : "0") + hh
  }:${(min > 9 ? "" : "0") + min}`;
};

Date.prototype.yyyymmdd = function yyyymmdd() {
  const yyyy = this.getFullYear();
  const mm = this.getMonth() + 1;
  const dd = this.getDate();

  if (Number.isNaN(yyyy) || Number.isNaN(mm) || Number.isNaN(dd)) {
    return undefined;
  }

  return `${yyyy}-${(mm > 9 ? "" : "0") + mm}-${(dd > 9 ? "" : "0") + dd}`;
};

Date.prototype.hhmm = function hhmm() {
  const hh = this.getHours();
  const min = this.getMinutes();

  return `${(hh > 9 ? "" : "0") + hh}:${(min > 9 ? "" : "0") + min}`;
};
